import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'
import hamper from './hamper.jpg'

import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
})


export const LolipopIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M18.107 17.156C16.6728 18.3493 14.8657 19.0018 13 19C12.8846 19.0009 12.7692 18.9972 12.654 18.989C10.6537 18.9046 8.75827 18.0711 7.34401 16.654C6.58595 15.8995 5.98801 14.9997 5.58601 14.0085C5.19743 13.0526 4.99841 12.0303 5.00001 10.9985C5.00001 9.96101 5.19651 8.94751 5.58601 7.98951C5.98798 6.99819 6.58592 6.09822 7.34401 5.34351C8.09894 4.58557 8.99908 3.98781 9.99051 3.58601C10.9462 3.19758 11.9684 2.99856 13 3.00001C14.0375 3.00001 15.0515 3.19651 16.0095 3.58601C17.0009 3.98781 17.9011 4.58557 18.656 5.34351C19.538 6.21871 20.201 7.28975 20.591 8.46951C20.9675 9.60888 21.0857 10.8178 20.937 12.0085C20.8704 12.5303 20.7532 13.0444 20.587 13.5435C20.0745 15.0535 19.405 16.0765 18.107 17.156V17.156Z"
      stroke="orange"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
    <path
      d="M7 16.9999L3 21.2424M11.0525 9.74436C11.0853 9.2858 11.2987 8.85897 11.6458 8.55752C11.9929 8.25606 12.4454 8.1046 12.904 8.13636C13.1761 8.15388 13.4419 8.22553 13.6859 8.3471C13.9299 8.46867 14.1472 8.63773 14.325 8.84436C14.5044 9.04936 14.6413 9.28797 14.7278 9.54633C14.8142 9.80469 14.8484 10.0777 14.8285 10.3494C14.8068 10.6767 14.7202 10.9965 14.5739 11.2902C14.4276 11.5838 14.2243 11.8454 13.976 12.0599C13.7285 12.2754 13.4408 12.4398 13.1294 12.5437C12.8181 12.6476 12.4893 12.689 12.162 12.6654C10.497 12.5484 9.2345 11.0994 9.3515 9.43336C9.4935 7.40336 11.2615 5.86686 13.2925 6.00886C13.8851 6.04862 14.4639 6.20546 14.9955 6.47031C15.5271 6.73516 16.0009 7.10276 16.3895 7.55186C16.7809 7.9985 17.0796 8.51846 17.2684 9.08153C17.4571 9.6446 17.5321 10.2396 17.489 10.8319C17.4396 11.5602 17.2462 12.2715 16.9201 12.9246C16.5939 13.5778 16.1415 14.1597 15.589 14.6369C14.5821 15.5175 13.2892 16.002 11.9515 15.9999C11.82 15.9999 11.688 15.9959 11.5525 15.9874C10.1585 15.8914 8.839 15.6184 7.7505 14.7499C6.69375 13.9145 5.91018 12.7829 5.5 11.4999L11.0525 9.74436Z"
      stroke="orange"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
  </svg>
  )
}

export const HamperIcon = (props) => {
  return (
    <img style={{ width: '15px', height: 'auto', margin: '0 3px' }} src={hamper} {...props} />
  )
}