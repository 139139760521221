import React from "react";
import {CSpinner} from "@coreui/react";

export const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"/>
	</div>
)

export const AbsoluteLoading = () => {
	return (
		<div className="fixed-loading">
			<CSpinner color='info' />
		</div>
	)
}
