import {TYPES} from "../types";

const initialState = {
	languages: [],
	currencies: []
}

export const Base = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case TYPES.SET_LANGUAGES:
			return {...state, ...rest}
		case TYPES.SET_CURRENCIES:
			return {...state, ...rest}
		default:
			return state
	}
}
