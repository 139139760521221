import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loading } from "./base/loading";
import './assets/scss/style.scss';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));

class App extends Component {

  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
